import {
  CHECKBOX_LEF, L_ACTIVE_CHECK_LF_BOX, RADIO_GROUP, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'
import { can } from '@core/libs/acl/utils'

export default function config() {
  const ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS = { action: 'change', subject: 'Payment Terms' }
  const MODULE_NAME = 'settings-payment-terms'
  const MODULE_NAME_CLONE = 'cloneData'

  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: can(ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS.action, ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS.subject) ? '10%' : '0%' } },
    { key: 'label', label: 'Name', thStyle: { width: can('change', 'Payment Terms') ? '80%' : '100%' } },
    { key: 'is_active', label: 'Status', thStyle: { width: can('change', 'Payment Terms') ? '80%' : '100%' } },
    { key: 'is_default', label: 'Default' },
  ]
  const trashTableColumns = [
    { key: 'label', label: 'Name', thStyle: { width: can('change', 'Payment Terms') ? '90%' : '100%' } },
    { key: 'is_active', label: 'Status' },
    { key: 'actions', label: 'Actions' },
  ]
  const fields = {
    label: {
      type: TEXT_INPUT,
      label: 'Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: 'required',
    },
    rules: {
      type: RADIO_GROUP,
      label: 'Rules',
      rules: 'required',
      list: [
        { value: '1', text: 'Charge at order approval' },
        { value: '2', text: 'Charge at dispatch' },
        { value: '0', text: 'Don’t Charge' },
      ],
    },
  }

  return {
    fields,
    tableColumns,
    MODULE_NAME,
    ACCESS_ABILITY_FOR_SYSTEM_PAYMENT_TERMS,
    trashTableColumns,
    MODULE_NAME_CLONE,
  }
}
