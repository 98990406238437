<template>
  <div>
    <b-row v-if="isUpdate">
      <b-col>
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <p class="text-left font-weight-bolder font-medium-5">
      {{ $t('Payment Term') }}
    </p>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="paymentTermCreateVal"
      >
        <div id="general-information">
          <div>
            <b-row>
              <b-col
                v-for="field in ['label']"
                :key="field"
                md="6"
              >
                <component
                  :is="fields[field].type"
                  v-model="paymentTerm[field]"
                  v-bind="getProps(field,fields)"
                  :is-editable="isEdit"
                />
              </b-col>
              <b-col
                v-for="field in ['is_active']"
                :key="field"
                class="is_active"
                md="6"
              ><span class="inputLabel">{{ $t('Status') }}</span>
                <component
                  :is="fields[field].type"
                  v-model="paymentTerm[field]"
                  v-bind="getProps(field,fields)"
                  class="is_active_items"
                  :is-editable="isEdit"
                />
              </b-col>
            </b-row>
            <label
              for="rules"
              class="d-block inputLabel pl-0 font-medium-5 mb-2"
            >{{ $t('Rules') }}</label>
            <b-row>
              <b-col md="6">
                <div
                  id="rules"
                  role="radiogroup"
                  tabindex="-1"
                  class="bv-no-focus-ring d-flex flex-column"
                >
                  <div
                    v-for="(item, index) in fields.rules.list"
                    :key="index"
                    class="custom-control custom-control-inline custom-radio _custom-radio"
                  >
                    <input
                      :id="`${fields.rules.label}_${index}`"
                      v-model="paymentTerm['rules']"
                      type="radio"
                      :value="item.value"
                      :disabled="isEdit"
                      :class="isEdit ? 'show-circle-on-disabled' : ''"
                      class="custom-control-input"
                    >
                    <label
                      :for="`${fields.rules.label}_${index}`"
                      :class="isEdit ? 'enable-title-when-disabled' : ''"
                      class="custom-control-label"
                    ><span>{{ item.text }}</span></label>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings-payment-terms' })"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="isCreateForm ? false : !isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>

import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BNavItem, BRow, VBTooltip,
} from 'bootstrap-vue'
import { getProps, scrollToError } from '@core/utils/utils'
import store from '@/store'
import config from '../paymentTermsConfig'

export default {
  name: 'PaymentTermsForm',
  components: {
    BNavItem,
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: {},
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    paymentTerm() {
      return this.$store.state[this.MODULE_NAME].paymentTermForm
    },
    paymentTermClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingPaymentTerm
    },
    isFormChanged() {
      return JSON.stringify(this.paymentTerm) !== this.paymentTermClone
    },
    isUpdate() {
      return this.paymentTerm.id
    },
  },
  mounted() {
    if (!this.isUpdate) this.isEdit = false
    if (this.isCreateForm) this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PAYMENT_TERM_FORM_CLONE`, this.paymentTerm)
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
      this.fields.rules.list.map(item => (item.hasOwnProperty('disabled') ? delete item.disabled : item.disabled = 'disabled'))
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.paymentTermCreateVal.validate().then(success => {
        if (success) {
          const data = this.paymentTerm
          const paymentTerm = this.mappingFields(Object.keys(this.fields), data, {
            id: data.id,
            label: data.label,
            is_active: data.is_active,
            rules: data.rules,
          })
          this.sendNotification(this, paymentTerm, `${this.MODULE_NAME}/${this.isUpdate ? 'update' : 'create'}`)
            .then(() => {
              this.clear()
            }).catch(err => {
              this.error = err.response?.data
            })
        } else {
          scrollToError(this, this.$refs.paymentTermCreateVal)
        }
      })
    },
    clear() {
      this.$refs.paymentTermCreateVal.reset()
      this.$router.push({ name: 'settings-payment-terms' })
    },
    loader() {
      this.$refs.paymentTermCreateVal.reset()

      const smth = JSON.parse(this.paymentTermClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
  },
  setup() {
    const MODULE_NAME = 'settings-payment-terms'
    const MODULE_NAME_CLONE = 'cloneData'

    const { fields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
      getProps,
    }
  },
}
</script>

<style scoped lang="scss">

._custom-radio{
  margin-left: 2px;
  margin-bottom: 8px;
}

.show-circle-on-disabled{
  opacity: 1;
}

.enable-title-when-disabled{
  color: #646464 !important;
}

</style>
