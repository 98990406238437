<template>
  <payment-terms-form
    ref="payment-terms-form"
    :is-create-form="true"
  />
</template>

<script>
import PaymentTermsForm from '@/views/settings/general/payment-terms/components/PaymentTermsForm.vue'

export default {
  name: 'PaymentTermsCreate',
  components: { PaymentTermsForm },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PAYMENT_TERM_FORM_CLONE`, {})
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['payment-terms-form']) {
      this.$refs['payment-terms-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  setup() {
    const MODULE_NAME = 'settings-payment-terms'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
